<template>
  <v-app>
    <v-main>
      <v-container>
        <v-row>
          <v-col>
            <v-card>
              <v-card-title class="headline">Dashboard</v-card-title>
              <v-tabs v-model="activeTab" background-color="primary" dark>
                <v-tab value="current">Current Races</v-tab>
                <v-tab value="expired">Expired Races</v-tab>
              </v-tabs>
              <v-tabs-window v-model="activeTab">
                <v-tabs-window-item value="current">
                  <v-card-actions>
                    <v-btn color="primary" @click="openAddDialog">Add Race</v-btn>
                  </v-card-actions>
                  <v-data-table :headers="headers" :items="currentRaces" item-key="raceId">
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-btn color="blue darken-1" @click="openEditDialog(item)">Edit</v-btn>
                      <v-btn color="red darken-1" @click="confirmDelete(item.raceId)">Delete</v-btn>
                    </template>
                  </v-data-table>
                </v-tabs-window-item>
                <v-tabs-window-item value="expired">
                  <v-data-table :headers="headers" :items="expiredRaces" item-key="raceId">
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-btn color="blue darken-1" @click="openEditDialog(item)">View</v-btn>
                    </template>
                  </v-data-table>
                </v-tabs-window-item>
              </v-tabs-window>
            </v-card>
          </v-col>
        </v-row>

        <!-- Add/Edit Race Dialog -->
        <v-dialog v-model="dialog" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">{{ isEdit ? 'Edit Race' : 'Add Race' }}</span>
            </v-card-title>
            <v-card-text>
              <v-form>
                <v-text-field v-model="formData.raceName" label="Race Name" required></v-text-field>
                <v-text-field v-model="formData.location" label="Location" required></v-text-field>
                <v-textarea v-model="formData.description" label="Description"></v-textarea>
                <v-text-field v-model="formData.startDate" label="Start Date" type="date" required></v-text-field>
                <v-text-field v-model="formData.endDate" label="End Date" type="date" required></v-text-field>
                <v-textarea v-model="formData.reward" label="Reward"></v-textarea>
                <v-textarea v-model="formData.rules" label="Rules"></v-textarea>
                <v-textarea v-model="formData.instruction" label="Instruction"></v-textarea>
                <v-file-input v-model="formData.racePicture" label="Race Picture" accept="image/*" @change="previewImage"></v-file-input>
                <v-img v-if="formData.racePicturePath" :src="formData.racePicturePath" max-height="200" class="mt-4"></v-img>
                <v-divider></v-divider>
                <br><br>
                <v-file-input v-model="formData.badgesPicture" label="Race Badge Picture" accept="image/*" @change="previewBadgeImage"></v-file-input>
                <v-img v-if="formData.badgesPicturePath" :src="formData.badgesPicturePath" max-height="200" class="mt-4"></v-img>
                <v-divider></v-divider>
                <br><br>
                <v-file-input v-model="formData.bibPicture" label="Race Bib Picture" accept="image/*" @change="previewBibImage"></v-file-input>
                <v-img v-if="formData.bibPicturePath" :src="formData.bibPicturePath" max-height="200" class="mt-4"></v-img>
                <v-divider></v-divider>
                <br><br>
                <v-file-input v-model="formData.certPicture" label="Race Cert Picture" accept="image/*" @change="previewCertImage"></v-file-input>
                <v-img v-if="formData.certPicturePath" :src="formData.certPicturePath" max-height="200" class="mt-4"></v-img>
                <v-divider></v-divider>
                <br><br>
                <h3>Racetypes</h3>
                <div v-for="(racetype, index) in formData.racetypes" :key="index" style="margin-bottom: 10px;">
                  <v-text-field v-model="racetype.name" label="Name" required></v-text-field>
                  <v-text-field v-model="racetype.price" label="Price" type="number" required></v-text-field>
                  <v-btn color="red darken-1" @click="removeRacetype(index)">Remove</v-btn>
                </div>
                <v-btn color="green darken-1" @click="addRacetype">Add Racetype</v-btn>
                <v-divider></v-divider>
                <h3>Race Distances (KM)</h3>
                <div v-for="(distance, index) in formData.distances" :key="index" style="margin-bottom: 10px;">
                  <v-text-field v-model="formData.distances[index]" label="Distance" type="number" required></v-text-field>
                  <v-btn color="red darken-1" @click="removeDistance(index)">Remove</v-btn>
                </div>
                <v-btn color="green darken-1" @click="addDistance">Add Distance</v-btn>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDialog">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="confirmSaveRace">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Confirm Add/Edit Dialog -->
        <v-dialog v-model="confirmDialog" max-width="400px">
          <v-card>
            <v-card-title class="headline">Confirm</v-card-title>
            <v-card-text>Are you sure you want to {{ isEdit ? 'edit' : 'add' }} this race?</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeConfirmDialog">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="saveRace">Confirm</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Confirm Delete Dialog -->
        <v-dialog v-model="confirmDeleteDialog" max-width="400px">
          <v-card>
            <v-card-title class="headline">Confirm Delete</v-card-title>
            <v-card-text>Are you sure you want to delete this race?</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeConfirmDeleteDialog">Cancel</v-btn>
              <v-btn color="red darken-1" text @click="deleteRace(confirmDeleteRaceId)">Delete</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import axios from '../axios';

export default {
  data() {
    return {
      activeTab: 'current',
      races: [],
      currentRaces: [],
      expiredRaces: [],
      dialog: false,
      confirmDialog: false,
      confirmDeleteDialog: false,
      isEdit: false,
      confirmDeleteRaceId: null,
      formData: {
        raceId: null,
        raceName: '',
        location: '',
        description: '',
        startDate: '',
        endDate: '',
        racePicture: null,
        racePicturePath: '',
        badgesPicture: null,
        badgesPicturePath: '',
        bibPicture: null,
        bibPicturePath: '',
        certPicture: null,
        certPicturePath: '',
        racetypes: [
          { name: 'Medal Only', price: null },
          { name: 'Shirt Only', price: null },
          { name: 'Medal + Shirt', price: null },
          { name: 'Digital Only', price: null },
        ],
        distances: [],
      },
      headers: [
        { title: 'Race Name', value: 'raceName' },
        { title: 'Start Date', value: 'startDate' },
        { title: 'End Date', value: 'endDate' },
        { title: 'Distances', value: 'distances' },
        { title: 'Actions', value: 'actions', sortable: false },
      ],
    };
  },
  created() {
    this.fetchRaces();
    this.fetchExpiredRaces();
  },
  methods: {
    fetchRaces() {
      axios.get('/races').then(response => {
        const currentDate = new Date().toISOString().split('T')[0];
        this.currentRaces = response.data.filter(race => race.endDate >= currentDate);
        this.currentRaces = this.currentRaces.map(race => ({
          ...race,
          distances: race.distances ? race.distances.split(',').join(',') : ''
        }));
      });
    },
    fetchExpiredRaces() {
      axios.get('/races/expired').then(response => {
        this.expiredRaces = response.data;
        this.expiredRaces = this.expiredRaces.map(race => ({
          ...race,
          distances: race.distances ? race.distances.split(',').join(',') : ''
        }));
      });
    },
    openAddDialog() {
      this.isEdit = false;
      this.formData = {
        raceId: null,
        raceName: '',
        location: '',
        description: '',
        startDate: '',
        endDate: '',
        racePicture: null,
        racePicturePath: '',
        badgesPicture: null,
        badgesPicturePath: '',
        bibPicture: null,
        bibPicturePath: '',
        certPicture: null,
        certPicturePath: '',
        racetypes: [
          { name: 'Medal Only', price: null },
          { name: 'Shirt Only', price: null },
          { name: 'Medal + Shirt', price: null },
          { name: 'Digital Only', price: null },
        ],
        distances: [],
      };
      this.dialog = true;
    },
    openEditDialog(race) {
      this.isEdit = true;
      this.formData = {
        ...race,
        racePicture: null,
        badgesPicture: null,
        bibPicture: null,
        certPicture: null,
        distances: race.distances ? race.distances.split(',') : []
      };
      this.fetchImageForEdit(race.racePicturePath);
      this.fetchBadgeForEdit(race.badgesPicturePath);
      this.fetchBibForEdit(race.bibPicturePath);
      this.fetchCertForEdit(race.certPicturePath);
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    confirmSaveRace() {
      this.confirmDialog = true;
    },
    closeConfirmDialog() {
      this.confirmDialog = false;
    },
    confirmDelete(raceId) {
      this.confirmDeleteRaceId = raceId;
      this.confirmDeleteDialog = true;
    },
    closeConfirmDeleteDialog() {
      this.confirmDeleteDialog = false;
    },
    addRacetype() {
      this.formData.racetypes.push({ name: '', price: null });
    },
    removeRacetype(index) {
      this.formData.racetypes.splice(index, 1);
    },
    addDistance() {
      this.formData.distances.push('');
    },
    removeDistance(index) {
      this.formData.distances.splice(index, 1);
    },
    fetchImageForEdit(filename) {
      axios.get(`races/images/${filename}`, { responseType: 'blob' })
        .then(response => {
          const url = URL.createObjectURL(new Blob([response.data]));
          this.formData.racePicturePath = url;
        })
        .catch(error => {
          console.error('There was an error fetching the image!', error);
        });
    },
    fetchBadgeForEdit(filename) {
      axios.get(`races/badges/${filename}`, { responseType: 'blob' })
        .then(response => {
          const url = URL.createObjectURL(new Blob([response.data]));
          this.formData.badgesPicturePath = url;
        })
        .catch(error => {
          console.error('There was an error fetching the image!', error);
        });
    },
    fetchBibForEdit(filename) {
      axios.get(`races/bibs/${filename}`, { responseType: 'blob' })
        .then(response => {
          const url = URL.createObjectURL(new Blob([response.data]));
          this.formData.bibPicturePath = url;
        })
        .catch(error => {
          console.error('There was an error fetching the image!', error);
        });
    },
    fetchCertForEdit(filename) {
      axios.get(`races/certs/${filename}`, { responseType: 'blob' })
        .then(response => {
          const url = URL.createObjectURL(new Blob([response.data]));
          this.formData.certPicturePath = url;
        })
        .catch(error => {
          console.error('There was an error fetching the image!', error);
        });
    },
    previewImage(event) {
      const file = event.target.files[0];
      if (file) {
        this.formData.racePicturePath = URL.createObjectURL(file);
      }
    },
    previewBadgeImage(event) {
      const file = event.target.files[0];
      if (file) {
        this.formData.badgesPicturePath = URL.createObjectURL(file);
      }
    },
    previewBibImage(event) {
      const file = event.target.files[0];
      if (file) {
        this.formData.bibPicturePath = URL.createObjectURL(file);
      }
    },
    previewCertImage(event) {
      const file = event.target.files[0];
      if (file) {
        this.formData.certPicturePath = URL.createObjectURL(file);
      }
    },
    saveRace() {
      if (this.formData.distances.length === 0) {
        alert('Please add at least one distance.');
        return;
      }

      let formData = new FormData();
      formData.append('raceName', this.formData.raceName);
      formData.append('location', this.formData.location);
      formData.append('description', this.formData.description);
      formData.append('startDate', this.formData.startDate);
      formData.append('endDate', this.formData.endDate);
      formData.append('reward', this.formData.reward);
      formData.append('rules', this.formData.rules);
      formData.append('instruction', this.formData.instruction);

      const racetypesData = this.formData.racetypes
        .map(rt => `${rt.name},${rt.price}`)
        .join(';');

      const distancesData = this.formData.distances.join(',');

      formData.append('racetypes', racetypesData);
      formData.append('distances', distancesData);

      if (this.formData.racePicture) {
        formData.append('image', this.formData.racePicture);
      }
      if (this.formData.badgesPicture) {
        formData.append('badgeImage', this.formData.badgesPicture);
      }
      if (this.formData.bibPicture) {
        formData.append('bibImage', this.formData.bibPicture);
      }
      if (this.formData.certPicture) {
        formData.append('certImage', this.formData.certPicture);
      }

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      if (this.isEdit) {
        axios.put(`/races/${this.formData.raceId}`, formData, config)
          .then(() => {
            this.fetchRaces();
            this.fetchExpiredRaces();
            this.closeDialog();
            this.closeConfirmDialog();
          })
          .catch(error => {
            console.error("There was an error!", error);
          });
      } else {
        axios.post('/races/upload', formData, config)
          .then(() => {
            this.fetchRaces();
            this.fetchExpiredRaces();
            this.closeDialog();
            this.closeConfirmDialog();
          })
          .catch(error => {
            console.error("There was an error!", error);
          });
      }
    },
    deleteRace(raceId) {
      axios.delete(`/races/${raceId}`)
        .then(() => {
          this.fetchRaces();
          this.fetchExpiredRaces();
          this.closeConfirmDeleteDialog();
        })
        .catch(error => {
          console.error("There was an error!", error);
        });
    },
  },
};
</script>
<style scoped>
/* Add any custom styles here */
</style>