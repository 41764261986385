<template>
  <v-app>
    <v-main>
      <v-container>
        <v-row>
          <v-col>
            <v-card>
              <v-card-title class="headline">Users</v-card-title>
              <v-card-text>
                <!-- Search Bar -->
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search by Username or Email"
                  single-line
                  hide-details
                ></v-text-field>

                <!-- Data Table -->
                <v-data-table
                  :headers="headers"
                  :items="filteredUsers"
                  item-key="id"
                  class="elevation-1"
                >
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-btn color="blue darken-1" @click="openViewDialog(item)">View</v-btn>
                    <v-btn color="red darken-1" @click="confirmBanUser(item.id)">Ban</v-btn>
                    <v-btn
                      color="orange darken-1"
                      v-if="!item.enabled"
                      @click="confirmResendVerificationEmail(item.id, item.email)"
                    >
                      Resend Verification Email
                    </v-btn>
                    <v-btn color="green darken-1" @click="confirmResetPassword(item.username, item.email)">
                      Reset Password
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <!-- View User Dialog -->
        <v-dialog v-model="dialog" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">View User</span>
            </v-card-title>
            <v-card-text>
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Username</v-list-item-title>
                    <v-list-item-subtitle>{{ selectedUser.username }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Email</v-list-item-title>
                    <v-list-item-subtitle>{{ selectedUser.email }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Role</v-list-item-title>
                    <v-list-item-subtitle>{{ selectedUser.role }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Enabled</v-list-item-title>
                    <v-list-item-subtitle>{{ selectedUser.enabled ? 'Yes' : 'No' }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Last Login Date</v-list-item-title>
                    <v-list-item-subtitle>{{ selectedUser.loginDate }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDialog">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Confirm Ban User Dialog -->
        <v-dialog v-model="confirmBanDialog" max-width="400px">
          <v-card>
            <v-card-title class="headline">Confirm Ban</v-card-title>
            <v-card-text>Are you sure you want to ban this user?</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeConfirmBanDialog">Cancel</v-btn>
              <v-btn color="red darken-1" text @click="banUser(confirmUserId)">Ban</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Confirm Resend Verification Email Dialog -->
        <v-dialog v-model="confirmResendDialog" max-width="400px">
          <v-card>
            <v-card-title class="headline">Resend Verification Email</v-card-title>
            <v-card-text>Are you sure you want to resend the verification email to {{ confirmUserEmail }}?</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeConfirmResendDialog">Cancel</v-btn>
              <v-btn color="orange darken-1" text @click="resendVerificationEmail(confirmUserEmail)">Resend</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Confirm Reset Password Dialog -->
        <v-dialog v-model="confirmResetDialog" max-width="400px">
          <v-card>
            <v-card-title class="headline">Reset Password</v-card-title>
            <v-card-text>Are you sure you want to reset the password for {{ confirmUsername }}?</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeConfirmResetDialog">Cancel</v-btn>
              <v-btn color="green darken-1" text @click="resetPassword(confirmUsername, confirmUserEmail)">Reset</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import axios from '../axios';

export default {
  data() {
    return {
      users: [],
      search: '', // Added search data property
      dialog: false,
      confirmBanDialog: false,
      confirmResendDialog: false,
      confirmResetDialog: false,
      confirmUserId: null,
      confirmUserEmail: null,
      confirmUsername: null,
      selectedUser: {},
      headers: [
        { title: 'Username', value: 'username' },
        { title: 'Email', value: 'email' },
        // Removed the Role column
        { title: 'Verified', value: 'enabled' }, // Renamed from 'Enabled' to 'Verified'
        { title: 'Actions', value: 'actions', sortable: false },
      ],
    };
  },
  computed: {
    filteredUsers() {
      if (!this.search) {
        return this.users;
      }
      const searchLower = this.search.toLowerCase();
      return this.users.filter(user => {
        return (
          (user.username && user.username.toLowerCase().includes(searchLower)) ||
          (user.email && user.email.toLowerCase().includes(searchLower))
        );
      });
    },
  },
  created() {
    this.fetchUsers();
  },
  methods: {
    fetchUsers() {
      axios.get('/users')
        .then(response => {
          this.users = response.data;
        })
        .catch(error => {
          console.error("There was an error fetching the users!", error);
        });
    },
    openViewDialog(user) {
      this.selectedUser = user;
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    confirmBanUser(userId) {
      this.confirmUserId = userId;
      this.confirmBanDialog = true;
    },
    closeConfirmBanDialog() {
      this.confirmBanDialog = false;
    },
    banUser(userId) {
      axios.put(`/users/${userId}/disable`)
        .then(() => {
          this.fetchUsers();
          this.closeConfirmBanDialog();
        })
        .catch(error => {
          console.error("There was an error banning the user!", error);
        });
    },
    confirmResendVerificationEmail(userId, email) {
      this.confirmUserId = userId;
      this.confirmUserEmail = email;
      this.confirmResendDialog = true;
    },
    closeConfirmResendDialog() {
      this.confirmResendDialog = false;
    },
    resendVerificationEmail(email) {
      axios.post('/api/send-verification-email', { email, userId: this.confirmUserId })
        .then(() => {
          this.closeConfirmResendDialog();
        })
        .catch(error => {
          console.error("There was an error resending the verification email!", error);
        });
    },
    confirmResetPassword(username, email) {
      this.confirmUsername = username;
      this.confirmUserEmail = email;
      this.confirmResetDialog = true;
    },
    closeConfirmResetDialog() {
      this.confirmResetDialog = false;
    },
    resetPassword(username, email) {
      axios.post('/forgot_password', { username, email })
        .then(() => {
          this.closeConfirmResetDialog();
        })
        .catch(error => {
          console.error("There was an error resetting the password!", error);
        });
    },
  },
};
</script>
<style scoped>
/* Add any custom styles here */
</style>