<template>
    <v-app>
      <v-main>
        <v-container>
          <v-row>
            <v-col>
              <v-card>
                <v-card-title class="headline">Voucher Dashboard</v-card-title>
                <v-tabs v-model="activeTab" background-color="primary" dark>
                  <v-tab value="enabled">Enabled Vouchers</v-tab>
                  <v-tab value="disabled">Disabled Vouchers</v-tab>
                </v-tabs>
                <v-tabs-window v-model="activeTab">
                  <v-tabs-window-item value="enabled">
                    <v-data-table :headers="headers" :items="enabledVouchers" item-key="voucherId">
                      <template v-slot:[`item.actions`]="{ item }">
                        <v-btn color="red darken-1" @click="confirmDisable(item.voucherId)">Disable</v-btn>
                      </template>
                    </v-data-table>
                  </v-tabs-window-item>
                  <v-tabs-window-item value="disabled">
                    <v-data-table :headers="headers" :items="disabledVouchers" item-key="voucherId">
                      <template v-slot:[`item.actions`]="{ item }">
                        <v-btn color="green darken-1" @click="confirmEnable(item.voucherId)">Enable</v-btn>
                      </template>
                    </v-data-table>
                  </v-tabs-window-item>
                </v-tabs-window>
              </v-card>
            </v-col>
          </v-row>
  
          <!-- Confirm Enable Dialog -->
          <v-dialog v-model="confirmEnableDialog" max-width="400px">
            <v-card>
              <v-card-title class="headline">Confirm Enable</v-card-title>
              <v-card-text>Are you sure you want to enable this voucher?</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeConfirmEnableDialog">Cancel</v-btn>
                <v-btn color="green darken-1" text @click="enableVoucher(confirmEnableVoucherId)">Enable</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
  
          <!-- Confirm Disable Dialog -->
          <v-dialog v-model="confirmDisableDialog" max-width="400px">
            <v-card>
              <v-card-title class="headline">Confirm Disable</v-card-title>
              <v-card-text>Are you sure you want to disable this voucher?</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeConfirmDisableDialog">Cancel</v-btn>
                <v-btn color="red darken-1" text @click="disableVoucher(confirmDisableVoucherId)">Disable</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-container>
      </v-main>
    </v-app>
  </template>
  
  <script>
  import axios from '../axios';
  
  export default {
    data() {
      return {
        activeTab: 'enabled',
        enabledVouchers: [],
        disabledVouchers: [],
        confirmEnableDialog: false,
        confirmDisableDialog: false,
        confirmEnableVoucherId: null,
        confirmDisableVoucherId: null,
        headers: [
            { title: 'ID', value: 'id' },
          { title: 'Voucher String', value: 'voucherString' },
          { title: 'Actions', value: 'actions', sortable: false },
        ],
      };
    },
    created() {
      this.fetchVouchers();
    },
    methods: {
      fetchVouchers() {
        axios.get('/voucher/enabled').then(response => {
          this.enabledVouchers = response.data;
        });
        axios.get('/voucher/disabled').then(response => {
          this.disabledVouchers = response.data;
        });
      },
      confirmEnable(voucherId) {
        this.confirmEnableVoucherId = voucherId;
        this.confirmEnableDialog = true;
      },
      closeConfirmEnableDialog() {
        this.confirmEnableDialog = false;
      },
      confirmDisable(voucherId) {
        this.confirmDisableVoucherId = voucherId;
        this.confirmDisableDialog = true;
      },
      closeConfirmDisableDialog() {
        this.confirmDisableDialog = false;
      },
      enableVoucher(voucherId) {
        axios.put(`/voucher/${voucherId}/enable`).then(() => {
          this.fetchVouchers();
          this.closeConfirmEnableDialog();
        }).catch(error => {
          console.error('There was an error enabling the voucher!', error);
        });
      },
      disableVoucher(voucherId) {
        axios.put(`/voucher/${voucherId}/disable`).then(() => {
          this.fetchVouchers();
          this.closeConfirmDisableDialog();
        }).catch(error => {
          console.error('There was an error disabling the voucher!', error);
        });
      },
    },
  };
  </script>
  
  <style scoped>
  /* Add any custom styles here */
  </style>
  