<template>
    <v-app>
      <v-main>
        <v-container>
          <v-row>
            <v-col>
              <v-card>
                <v-card-title class="headline">Configurations</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click="openAddDialog">Add Configuration</v-btn>
                </v-card-actions>
                <v-data-table :headers="headers" :items="configurations" item-key="configKey">
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-btn color="blue darken-1" @click="openEditDialog(item)">Edit</v-btn>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="red darken-1"
                          v-bind="attrs"
                          v-on="on"
                          @click="confirmDelete(item.configKey)"
                          :disabled="nonDeletableKeys.includes(item.configKey)"
                        >
                          Delete
                        </v-btn>
                      </template>
                      <span v-if="nonDeletableKeys.includes(item.configKey)">Cannot delete protected configuration</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
  
          <!-- Add Configuration Dialog -->
          <v-dialog v-model="addDialog" max-width="600px">
            <v-card>
              <v-card-title class="headline">Add Configuration</v-card-title>
              <v-card-text>
                <v-form ref="addForm">
                  <v-text-field
                    v-model="newConfig.configKey"
                    label="Config Key"
                    :rules="[rules.required]"
                    @input="newConfig.configKey = newConfig.configKey.toUpperCase()"
                  ></v-text-field>
                  <v-text-field
                    v-model="newConfig.configType"
                    label="Config Type"
                    :rules="[rules.required]"
                    @input="newConfig.configType = newConfig.configType.toUpperCase()"
                  ></v-text-field>
                  <v-text-field
                    v-model="newConfig.description"
                    label="Description"
                    :rules="[rules.required]"
                    @input="newConfig.description = newConfig.description.toUpperCase()"
                  ></v-text-field>
                  <v-textarea
                    v-model="newConfig.configValue"
                    label="Config Value"
                    :auto-grow="true"
                    :rows="5"
                    :rules="[rules.required]"
                  ></v-textarea>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeAddDialog">Cancel</v-btn>
                <v-btn color="green darken-1" text @click="addConfiguration">Add</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
  
          <!-- Edit Configuration Dialog -->
          <v-dialog v-model="editDialog" max-width="600px">
            <v-card>
              <v-card-title class="headline">Edit Configuration</v-card-title>
              <v-card-text>
                <v-form ref="editForm">
                  <v-text-field
                    v-model="editConfig.configKey"
                    label="Config Key"
                    :rules="[rules.required]"
                    disabled
                  ></v-text-field>
                  <v-text-field
                    v-model="editConfig.configType"
                    label="Config Type"
                    :rules="[rules.required]"
                    @input="editConfig.configType = editConfig.configType.toUpperCase()"
                  ></v-text-field>
                  <v-text-field
                    v-model="editConfig.description"
                    label="Description"
                    @input="editConfig.description = editConfig.description.toUpperCase()"
                  ></v-text-field>
                  <v-textarea
                    v-model="editConfig.configValue"
                    label="Config Value"
                    :auto-grow="true"
                    :rows="5"
                    :rules="[rules.required]"
                  ></v-textarea>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeEditDialog">Cancel</v-btn>
                <v-btn color="green darken-1" text @click="updateConfiguration">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
  
          <!-- Confirm Delete Dialog -->
          <v-dialog v-model="confirmDeleteDialog" max-width="400px">
            <v-card>
              <v-card-title class="headline">Confirm Delete</v-card-title>
              <v-card-text>
                Are you sure you want to delete this configuration?
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeConfirmDeleteDialog">Cancel</v-btn>
                <v-btn color="red darken-1" text @click="deleteConfiguration(confirmDeleteKey)">Delete</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
  
        </v-container>
      </v-main>
    </v-app>
  </template>
  
  <script>
  import axios from '../axios';
  
  export default {
    data() {
      return {
        configurations: [],
        headers: [
          { title: 'Config Key', value: 'configKey' },
          { title: 'Config Type', value: 'configType' },
          { title: 'Description', value: 'description' },
          { title: 'Config Value', value: 'configValue' },
          { title: 'Actions', value: 'actions', sortable: false },
        ],
        addDialog: false,
        editDialog: false,
        confirmDeleteDialog: false,
        newConfig: {
          configKey: '',
          configType: '',
          description: '',
          configValue: '',
        },
        editConfig: {
          configKey: '',
          configType: '',
          description: '',
          configValue: '',
        },
        confirmDeleteKey: '',
        nonDeletableKeys: [
          "VERIFICATION_EMAIL_TEMPLATE",
          "REGISTRATION_EMAIL_TEMPLATE",
          "REMINDER_REGISTRATION_EMAIL_TEMPLATE",
          "RACE_COMPLETION_EMAIL_TEMPLATE",
          "RESET_PASSWORD_EMAIL_TEMPLATE",
          "TEMPORARY_PASSWORD_EMAIL_TEMPLATE",
          "REGISTRATION_REJECTION_EMAIL_TEMPLATE",
          "SUBMISSION_APPROVAL_EMAIL_TEMPLATE",
          "delivery_fee"
        ],
        rules: {
          required: value => !!value || 'Required.',
        },
      };
    },
    created() {
      this.fetchConfigurations();
    },
    methods: {
      fetchConfigurations() {
        axios.get('/configurations')
          .then(response => {
            this.configurations = response.data;
          })
          .catch(error => {
            console.error('Error fetching configurations', error);
          });
      },
      openAddDialog() {
        this.newConfig = {
          configKey: '',
          configType: '',
          description: '',
          configValue: '',
        };
        this.addDialog = true;
      },
      closeAddDialog() {
        this.addDialog = false;
      },
      addConfiguration() {
        this.$refs.addForm.validate();
        axios.post('/configurations', this.newConfig)
          .then(response => {
            console.log(response)
            this.fetchConfigurations();
            this.closeAddDialog();
          })
          .catch(error => {
            console.error('Error adding configuration', error);
          });
      },
      openEditDialog(item) {
        this.editConfig = Object.assign({}, item);
        this.editDialog = true;
      },
      closeEditDialog() {
        this.editDialog = false;
      },
      updateConfiguration() {
        this.$refs.editForm.validate();
        axios.put(`/configurations/${this.editConfig.configKey}`, this.editConfig)
          .then(response => {
            console.log(response)
            this.fetchConfigurations();
            this.closeEditDialog();
          })
          .catch(error => {
            console.error('Error updating configuration', error);
          });
      },
      confirmDelete(configKey) {
        this.confirmDeleteKey = configKey;
        this.confirmDeleteDialog = true;
      },
      closeConfirmDeleteDialog() {
        this.confirmDeleteDialog = false;
      },
      deleteConfiguration(configKey) {
        axios.delete(`/configurations/${configKey}`)
          .then(response => {
            console.log(response)
            this.fetchConfigurations();
            this.closeConfirmDeleteDialog();
          })
          .catch(error => {
            console.error('Error deleting configuration', error);
          });
      },
    },
  };
  </script>
  
  <style scoped>
  /* Add any custom styles here */
  </style>