<template>
    <v-app>
      <v-main>
        <v-container>
          <v-row>
            <v-col>
              <v-card>
                <v-card-title class="headline">Shop Dashboard</v-card-title>
                <v-tabs v-model="activeTab" background-color="primary" dark>
                  <v-tab value="notDelivered">Not Delivered</v-tab>
                  <v-tab value="delivered">Delivered</v-tab>
                </v-tabs>
                <v-tabs-window v-model="activeTab">
                    <v-tabs-window-item value="notDelivered">
                    <v-data-table :headers="headers" :items="notDeliveredShops" item-key="shopId">
                      <template v-slot:[`item.actions`]="{ item }">
                        <v-btn color="blue darken-1" @click="openEditDialog(item)">Edit</v-btn>
                        <v-btn color="green darken-1" @click="confirmDeliver(item.shopId)">Deliver</v-btn>
                        <v-btn color="red darken-1" @click="confirmDelete(item.shopId)">Delete</v-btn>
                      </template>
                    </v-data-table>
                  </v-tabs-window-item>
                  <v-tabs-window-item  value="delivered">
                    <v-data-table :headers="headers" :items="deliveredShops" item-key="shopId">
                      <template v-slot:[`item.actions`]="{ item }">
                        <v-btn color="blue darken-1" @click="openEditDialog(item)">View</v-btn>
                      </template>
                    </v-data-table>
                </v-tabs-window-item>
                </v-tabs-window>
              </v-card>
            </v-col>
          </v-row>
  
          <!-- Add/Edit Shop Dialog -->
          <v-dialog v-model="dialog" max-width="600px">
            <v-card>
              <v-card-title>
                <span class="headline">{{ isEdit ? 'Edit Shop' : 'Add Shop' }}</span>
              </v-card-title>
              <v-card-text>
                <v-form>
                  <v-text-field v-model="formData.userId" label="User ID" required></v-text-field>
                  <v-text-field v-model="formData.username" label="Username" required></v-text-field>
                  <v-text-field v-model="formData.name" label="Shop Name" required></v-text-field>
                  <v-text-field v-model="formData.email" label="Email" type="email" required></v-text-field>
                  <v-text-field v-model="formData.contactNumber" label="Contact Number" required></v-text-field>
                  <v-text-field v-model="formData.totalAmount" label="Total Amount" type="number" required></v-text-field>
                  <v-textarea v-model="formData.itemIds" label="Item IDs (comma separated)" required></v-textarea>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDialog">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="confirmSaveShop">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
  
          <!-- Confirm Add/Edit Dialog -->
          <v-dialog v-model="confirmDialog" max-width="400px">
            <v-card>
              <v-card-title class="headline">Confirm</v-card-title>
              <v-card-text>Are you sure you want to {{ isEdit ? 'edit' : 'add' }} this shop?</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeConfirmDialog">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="saveShop">Confirm</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
  
          <!-- Confirm Delete Dialog -->
          <v-dialog v-model="confirmDeleteDialog" max-width="400px">
            <v-card>
              <v-card-title class="headline">Confirm Delete</v-card-title>
              <v-card-text>Are you sure you want to delete this shop?</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeConfirmDeleteDialog">Cancel</v-btn>
                <v-btn color="red darken-1" text @click="deleteShop(confirmDeleteShopId)">Delete</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
  
          <!-- Confirm Deliver Dialog -->
          <v-dialog v-model="confirmDeliverDialog" max-width="400px">
            <v-card>
              <v-card-title class="headline">Confirm Deliver</v-card-title>
              <v-card-text>Are you sure you want to mark this shop as delivered?</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeConfirmDeliverDialog">Cancel</v-btn>
                <v-btn color="green darken-1" text @click="deliverShop(confirmDeliverShopId)">Deliver</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-container>
      </v-main>
    </v-app>
  </template>
  
  <script>
  import axios from '../axios';
  
  export default {
    data() {
      return {
        activeTab: 'notDelivered',
        shops: [],
        notDeliveredShops: [],
        deliveredShops: [],
        dialog: false,
        confirmDialog: false,
        confirmDeleteDialog: false,
        confirmDeliverDialog: false,
        isEdit: false,
        confirmDeleteShopId: null,
        confirmDeliverShopId: null,
        formData: {
          shopId: null,
          userId: '',
          username: '',
          name: '',
          email: '',
          contactNumber: '',
          totalAmount: null,
          itemIds: '',
        },
        headers: [
          { title: 'Shop Name', value: 'name' },
          { title: 'Username', value: 'username' },
          { title: 'Email', value: 'email' },
          { title: 'Total Amount', value: 'totalAmount' },
          { title: 'Actions', value: 'actions', sortable: false },
        ],
      };
    },
    created() {
      this.fetchShops();
    },
    methods: {
      fetchShops() {
        axios.get('/shop/not-delivered').then(response => {
          this.notDeliveredShops = response.data;
        });
        axios.get('/shop/delivered').then(response => {
          this.deliveredShops = response.data;
        });
      },
      openAddDialog() {
        this.isEdit = false;
        this.formData = {
          shopId: null,
          userId: '',
          username: '',
          name: '',
          email: '',
          contactNumber: '',
          totalAmount: null,
          itemIds: '',
        };
        this.dialog = true;
      },
      openEditDialog(shop) {
        this.isEdit = true;
        this.formData = {
          ...shop,
        };
        this.dialog = true;
      },
      closeDialog() {
        this.dialog = false;
      },
      confirmSaveShop() {
        this.confirmDialog = true;
      },
      closeConfirmDialog() {
        this.confirmDialog = false;
      },
      confirmDelete(shopId) {
        this.confirmDeleteShopId = shopId;
        this.confirmDeleteDialog = true;
      },
      closeConfirmDeleteDialog() {
        this.confirmDeleteDialog = false;
      },
      confirmDeliver(shopId) {
        this.confirmDeliverShopId = shopId;
        this.confirmDeliverDialog = true;
      },
      closeConfirmDeliverDialog() {
        this.confirmDeliverDialog = false;
      },
      saveShop() {
        const config = {
          headers: {
            'Content-Type': 'application/json',
          },
        };
  
        if (this.isEdit) {
          axios.put(`/shop/${this.formData.shopId}`, this.formData, config)
            .then(() => {
              this.fetchShops();
              this.closeDialog();
              this.closeConfirmDialog();
            })
            .catch(error => {
              console.error("There was an error!", error);
            });
        } else {
          axios.post('/shop/create', this.formData, config)
            .then(() => {
              this.fetchShops();
              this.closeDialog();
              this.closeConfirmDialog();
            })
            .catch(error => {
              console.error("There was an error!", error);
            });
        }
      },
      deleteShop(shopId) {
        axios.delete(`/shop/${shopId}`)
          .then(() => {
            this.fetchShops();
            this.closeConfirmDeleteDialog();
          })
          .catch(error => {
            console.error("There was an error!", error);
          });
      },
      deliverShop(shopId) {
        axios.put(`/shop/${shopId}/deliver`)
          .then(() => {
            this.fetchShops();
            this.closeConfirmDeliverDialog();
          })
          .catch(error => {
            console.error("There was an error!", error);
          });
      },
    },
  };
  </script>
  
  <style scoped>
  /* Add any custom styles here */
  </style>
  