<template>
  <v-app>
    <v-main>
      <v-container>
        <v-row>
          <v-col>
            <v-card>
              <v-card-title class="headline">Registrations</v-card-title>

              <!-- Search Bar -->
              <v-text-field
                v-model="search"
                label="Search by User Name, Email, Race, or Race Type"
                append-icon="mdi-magnify"
                clearable
                class="mb-4"
              ></v-text-field>

              <!-- Tabs -->
              <v-tabs v-model="activeTab" background-color="primary" dark>
                <v-tab value="non">Non-Approved</v-tab>
                <v-tab value="all">All Registrations</v-tab>
              </v-tabs>

              <!-- Tab Contents -->
              <v-tabs-window v-model="activeTab">
                <!-- Non-Approved Registrations Tab -->
                <v-tabs-window-item value="non">
                  <v-data-table
                    :headers="headers"
                    :items="filteredNonApprovedRegistrations"
                    item-key="registration.id"
                    class="elevation-1"
                  >
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-btn color="blue darken-1" @click="editRegistration(item)">Edit</v-btn>
                      <v-btn color="green darken-1" @click="confirmApprove(item.registration.id)">Approve</v-btn>
                      <v-btn color="red darken-1" @click="confirmDelete(item.registration.id)">Delete</v-btn>
                    </template>
                  </v-data-table>
                </v-tabs-window-item>

                <!-- All Registrations Tab -->
                <v-tabs-window-item value="all">
                  <v-data-table
                    :headers="headers"
                    :items="filteredAllRegistrations"
                    item-key="registration.id"
                    class="elevation-1"
                  >
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-btn color="blue darken-1" @click="editRegistration(item)">Edit</v-btn>
                      <v-btn color="red darken-1" @click="confirmDelete(item.registration.id)">Delete</v-btn>
                    </template>
                  </v-data-table>
                </v-tabs-window-item>
              </v-tabs-window>
            </v-card>
          </v-col>
        </v-row>

        <!-- Edit Registration Dialog -->
        <v-dialog v-model="editDialog" max-width="600px">
          <v-card>
            <v-card-title class="headline">Edit Registration</v-card-title>
            <v-card-text>
              <v-form ref="form" v-model="valid">
                <v-text-field
                  label="Registration ID"
                  v-model="selectedRegistration.id"
                  readonly
                ></v-text-field>

                <v-select
                  label="Race Type"
                  :items="raceTypes"
                  item-title="name"
                  item-value="name"
                  v-model="selectedRegistration.racetype"
                  :rules="[v => !!v || 'Race Type is required']"
                  required
                  @change="updatePrice"
                ></v-select>

                <v-text-field
                  label="Price (Php)"
                  v-show="false"
                  v-model="selectedRegistration.price"
                  readonly
                ></v-text-field>

                <v-select
                  label="T-Shirt Size"
                  :items="tshirtSizes"
                  v-model="selectedRegistration.tshirtSize"
                  :rules="[v => !!v || 'T-Shirt Size is required']"
                  required
                ></v-select>

                <!-- Updated Race Distance Select -->
                <v-select
                  label="Race Distance (km)"
                  :items="raceDistances"
                  v-model="selectedRegistration.raceDistance"
                  :rules="[v => !!v || 'Race Distance is required']"
                  required
                ></v-select>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeEditDialog">Cancel</v-btn>
              <v-btn color="green darken-1" text @click="saveRegistration">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Confirm Approve Dialog -->
        <v-dialog v-model="confirmDialog" max-width="400px">
          <v-card>
            <v-card-title class="headline">Confirm Approve</v-card-title>
            <v-card-text>Are you sure you want to approve this registration?</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeConfirmDialog">Cancel</v-btn>
              <v-btn color="green darken-1" text @click="approveRegistration(confirmRegistrationId)">Approve</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Confirm Delete Dialog -->
        <v-dialog v-model="deleteDialog" max-width="400px">
          <v-card>
            <v-card-title class="headline">Confirm Delete</v-card-title>
            <v-card-text>Are you sure you want to delete this registration?</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDeleteDialog">Cancel</v-btn>
              <v-btn color="red darken-1" text @click="deleteRegistration(deleteRegistrationId)">Delete</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Snackbar for Notifications -->
        <v-snackbar
          v-model="snackbar"
          :color="snackbarColor"
          timeout="3000"
          top
          right
        >
          {{ snackbarMessage }}
          <v-btn color="white" text @click="snackbar = false">
            Close
          </v-btn>
        </v-snackbar>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import axios from '../axios';
import debounce from 'lodash/debounce';

export default {
  data() {
    return {
      activeTab: 'non',
      search: '',
      nonApprovedRegistrations: [],
      allRegistrations: [],
      confirmDialog: false,
      confirmRegistrationId: null,
      deleteDialog: false,
      deleteRegistrationId: null,
      editDialog: false,
      selectedRegistration: {
        id: null,
        racetype: '',
        price: 0,
        tshirtSize: '',
        raceDistance: null,
      },
      raceTypes: [], // Array of objects: { racetypeId, name, price }
      raceTypeMap: {}, // Mapping from racetype name to price
      tshirtSizes: ['NONE', 'XS', 'S', 'M', 'L', 'XL', 'XXL'],
      raceDistances: [], // Updated to be an array of numbers
      headers: [
        { title: 'Registration ID', value: 'registration.id' },
        { title: 'User Name', value: 'user.username' },
        { title: 'User Email', value: 'user.email' },
        { title: 'Race', value: 'race.raceName' },
        { title: 'Race Type', value: 'registration.racetype' },
        { title: 'Contact Number', value: 'registration.contactNumber' },
        { title: 'T-Shirt Size', value: 'registration.tshirtSize' },
        { title: 'Race Distance (km)', value: 'registration.raceDistance' },
        { title: 'Price (Php)', value: 'registration.price' },
        { title: 'Registration Date', value: 'registration.registrationDate' },
        { title: 'Actions', value: 'actions', sortable: false },
      ],
      snackbar: false,
      snackbarMessage: '',
      snackbarColor: '',
      valid: false,
      debouncedSearch: null,
    };
  },
  computed: {
    filteredNonApprovedRegistrations() {
      if (!this.search) {
        return this.nonApprovedRegistrations;
      }
      const query = this.search.toLowerCase();
      return this.nonApprovedRegistrations.filter((registration) => {
        const userName = registration.user?.username?.toLowerCase() || '';
        const userEmail = registration.user?.email?.toLowerCase() || '';
        const raceName = registration.race?.raceName?.toLowerCase() || '';
        const raceType = registration.racetype?.toLowerCase() || '';

        return (
          userName.includes(query) ||
          userEmail.includes(query) ||
          raceName.includes(query) ||
          raceType.includes(query)
        );
      });
    },

    filteredAllRegistrations() {
      if (!this.search) {
        return this.allRegistrations;
      }
      const query = this.search.toLowerCase();
      return this.allRegistrations.filter((registration) => {
        const userName = registration.user?.username?.toLowerCase() || '';
        const userEmail = registration.user?.email?.toLowerCase() || '';
        const raceName = registration.race?.raceName?.toLowerCase() || '';
        const raceType = registration.racetype?.toLowerCase() || '';

        return (
          userName.includes(query) ||
          userEmail.includes(query) ||
          raceName.includes(query) ||
          raceType.includes(query)
        );
      });
    },
  },
  created() {
    this.fetchNonApprovedRegistrations();
    this.fetchAllRegistrations();
    this.debouncedSearch = debounce(this.handleSearch, 300);
  },
  watch: {
    search(newVal) {
      this.debouncedSearch(newVal);
    },
  },
  methods: {
    fetchNonApprovedRegistrations() {
      axios
        .get('/api/registrations/non-approved')
        .then((response) => {
          this.nonApprovedRegistrations = response.data;
        })
        .catch((error) => {
          console.error('Error fetching non-approved registrations:', error);
          this.showSnackbar('Failed to fetch Non-Approved Registrations.', 'error');
        });
    },

    fetchAllRegistrations() {
      axios
        .get('/api/registrations/all')
        .then((response) => {
          this.allRegistrations = response.data;
        })
        .catch((error) => {
          console.error('Error fetching all registrations:', error);
          this.showSnackbar('Failed to fetch All Registrations.', 'error');
        });
    },

    handleSearch(query) {
      console.log(query)
      // No additional handling needed as computed properties manage filtering
    },

    editRegistration(item) {
  // Deep clone the registration object to avoid mutating the original data
  this.selectedRegistration = JSON.parse(JSON.stringify(item.registration));

  // Extract the race type and distance for the select inputs
  let racetype = this.selectedRegistration.racetype;
  if (typeof racetype === 'object' && racetype !== null) {
    racetype = racetype.name;
  }

  let raceDistance = this.selectedRegistration.raceDistance;
  if (typeof raceDistance === 'object' && raceDistance !== null) {
    raceDistance = raceDistance.distance;
  }

  // Update selectedRegistration with the extracted values
  this.selectedRegistration.racetype = racetype;
  this.selectedRegistration.raceDistance = raceDistance;

  const raceId = item.race?.raceId;

  if (raceId) {
    // Fetch Race Types
    axios
      .get(`/races/${raceId}/racetypes`)
      .then((response) => {
        this.raceTypes = response.data;
        this.raceTypeMap = {};
        response.data.forEach((type) => {
          this.raceTypeMap[type.name] = type.price;
        });

        // Ensure price is updated based on the selected race type
        this.updatePrice(this.selectedRegistration.racetype);
      })
      .catch((error) => {
        console.error(error);
        this.showSnackbar('Failed to fetch Race Types.', 'error');
      });

    // Fetch Race Distances
    axios
      .get(`/races/${raceId}/distances`)
      .then((response) => {
        this.raceDistances = response.data; // Use distances as-is
      })
      .catch((error) => {
        console.error(error);
        this.showSnackbar('Failed to fetch Race Distances.', 'error');
      });
  } else {
    this.showSnackbar('Race ID is undefined.', 'error');
  }

  this.editDialog = true;
},


    closeEditDialog() {
      this.editDialog = false;
      this.selectedRegistration = {
        id: null,
        racetype: '',
        price: 0,
        tshirtSize: '',
        raceDistance: null,
      };
      this.raceTypes = [];
      this.raceTypeMap = {};
      this.raceDistances = [];
    },

    updatePrice(selectedRaceType) {
      console.log('Selected Race Type:', selectedRaceType);
      const price = this.raceTypeMap[selectedRaceType] || 0;
      this.selectedRegistration.price = price;
    },

    saveRegistration() {
  this.$refs.form.validate();
  if (!this.valid) {
    this.showSnackbar('Please fill out all required fields correctly.', 'error');
    return;
  }

  // Ensure raceDistance is a number
  this.selectedRegistration.raceDistance = parseFloat(this.selectedRegistration.raceDistance);
  if (isNaN(this.selectedRegistration.raceDistance)) {
    this.showSnackbar('Invalid Race Distance.', 'error');
    return;
  }

  // Prepare the payload to include the whole registration
  
  this.updatePrice(this.selectedRegistration.racetype);
  const payload = this.selectedRegistration;
  

  axios
    .put(`/api/registrations/${this.selectedRegistration.id}`, payload)
    .then(() => {
      this.fetchNonApprovedRegistrations();
      this.fetchAllRegistrations();
      this.closeEditDialog();
      this.showSnackbar('Registration updated successfully!', 'success');
    })
    .catch((error) => {
      console.error('There was an error saving the registration!', error);
      this.showSnackbar('Error saving registration.', 'error');
    });
},


    confirmApprove(registrationId) {
      this.confirmRegistrationId = registrationId;
      this.confirmDialog = true;
    },

    closeConfirmDialog() {
      this.confirmDialog = false;
    },

    approveRegistration(registrationId) {
      axios
        .post(`/api/registrations/approve/${registrationId}`)
        .then(() => {
          this.fetchNonApprovedRegistrations();
          this.fetchAllRegistrations();
          this.closeConfirmDialog();
          this.showSnackbar('Registration approved successfully!', 'success');
        })
        .catch((error) => {
          console.error('There was an error approving the registration!', error);
          this.showSnackbar('Error approving registration.', 'error');
        });
    },

    confirmDelete(registrationId) {
      this.deleteRegistrationId = registrationId;
      this.deleteDialog = true;
    },

    closeDeleteDialog() {
      this.deleteDialog = false;
    },

    deleteRegistration(registrationId) {
      axios
        .post(`/api/registrations/reject/${registrationId}`)
        .then(() => {
          this.fetchNonApprovedRegistrations();
          this.fetchAllRegistrations();
          this.closeDeleteDialog();
          this.showSnackbar('Registration deleted successfully!', 'success');
        })
        .catch((error) => {
          console.error('There was an error deleting the registration!', error);
          this.showSnackbar('Error deleting registration.', 'error');
        });
    },

    showSnackbar(message, color) {
      this.snackbarMessage = message;
      this.snackbarColor = color;
      this.snackbar = true;
    },
  },
};
</script>

<style scoped>
/* Add any custom styles here */

/* Adjust the spacing between buttons in the data table */
.v-data-table .v-btn {
  margin-right: 8px;
}

/* Adjust the search bar margin */
.v-text-field {
  margin-bottom: 16px;
}
</style>
