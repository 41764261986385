<template>
  <v-app>
    <v-main>
      <v-container>
        <v-row>
          <v-col>
            <v-card>
              <v-card-title class="headline">Rewards Dashboard</v-card-title>
              <v-card-actions>
                <v-btn color="primary" @click="openAddDialog">Add Reward</v-btn>
              </v-card-actions>
              <v-data-table :headers="headers" :items="rewards" item-key="id">
                <template v-slot:[`item.actions`]="{ item }">
                  <v-btn color="blue darken-1" @click="openEditDialog(item)">Edit</v-btn>
                  <v-btn color="red darken-1" @click="confirmDelete(item.id)">Delete</v-btn>
                </template>
                <template v-slot:[`item.voucher`]="{ item }">
                  <v-checkbox v-model="item.voucher" disabled></v-checkbox>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>

        <!-- Add/Edit Reward Dialog -->
        <v-dialog v-model="dialog" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">{{ isEdit ? 'Edit Reward' : 'Add Reward' }}</span>
            </v-card-title>
            <v-card-text>
              <v-form>
                <v-text-field v-model="formData.name" label="Reward Name" required></v-text-field>
                <v-textarea v-model="formData.description" label="Description"></v-textarea>
                <v-text-field v-model="formData.amount" label="Amount" type="number" required></v-text-field>
                <v-checkbox v-model="formData.voucher" label="Voucher"></v-checkbox>
                <v-file-input v-model="formData.imageFile" label="Reward Image" accept="image/*" @change="previewImage"></v-file-input>
                <v-img v-if="formData.imageUrl" :src="formData.imageUrl" max-height="200" class="mt-4"></v-img>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDialog">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="confirmSaveReward">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Confirm Add/Edit Dialog -->
        <v-dialog v-model="confirmDialog" max-width="400px">
          <v-card>
            <v-card-title class="headline">Confirm</v-card-title>
            <v-card-text>Are you sure you want to {{ isEdit ? 'edit' : 'add' }} this reward?</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeConfirmDialog">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="saveReward">Confirm</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Confirm Delete Dialog -->
        <v-dialog v-model="confirmDeleteDialog" max-width="400px">
          <v-card>
            <v-card-title class="headline">Confirm Delete</v-card-title>
            <v-card-text>Are you sure you want to delete this reward?</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeConfirmDeleteDialog">Cancel</v-btn>
              <v-btn color="red darken-1" text @click="deleteReward(confirmDeleteRewardId)">Delete</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import axios from '../axios';

export default {
  data() {
    return {
      rewards: [],
      dialog: false,
      confirmDialog: false,
      confirmDeleteDialog: false,
      isEdit: false,
      confirmDeleteRewardId: null,
      formData: {
        id: null,
        name: '',
        description: '',
        amount: null,
        imageFile: null,
        imageUrl: '',
        voucher: false,
      },
      headers: [
          { title: 'Item ID', value: 'id' },
        { title: 'Reward Name', value: 'name' },
        { title: 'Amount', value: 'amount' },
        { title: 'Voucher', value: 'voucher' },
        { title: 'Actions', value: 'actions', sortable: false },
      ],
    };
  },
  created() {
    this.fetchRewards();
  },
  methods: {
    fetchRewards() {
      axios.get('/rewards').then(response => {
        this.rewards = response.data;
      });
    },
    openAddDialog() {
      this.isEdit = false;
      this.formData = {
        id: null,
        name: '',
        description: '',
        amount: null,
        imageFile: null,
        imageUrl: '',
        voucher: false,
      };
      this.dialog = true;
    },
    openEditDialog(reward) {
      this.isEdit = true;
      this.formData = {
        ...reward,
        imageFile: null,
      };
      this.fetchImageForEdit(reward.imageUrl);
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    confirmSaveReward() {
      this.confirmDialog = true;
    },
    closeConfirmDialog() {
      this.confirmDialog = false;
    },
    confirmDelete(rewardId) {
      this.confirmDeleteRewardId = rewardId;
      this.confirmDeleteDialog = true;
    },
    closeConfirmDeleteDialog() {
      this.confirmDeleteDialog = false;
    },
    fetchImageForEdit(filename) {
      axios.get(`rewards/images/${filename}`, { responseType: 'blob' })
        .then(response => {
          const url = URL.createObjectURL(new Blob([response.data]));
          this.formData.imageUrl = url;
        })
        .catch(error => {
          console.error('There was an error fetching the image!', error);
        });
    },
    previewImage(event) {
      const file = event.target.files[0];
      if (file) {
        this.formData.imageUrl = URL.createObjectURL(file);
      }
    },
    saveReward() {
      let formData = new FormData();
      formData.append('name', this.formData.name);
      formData.append('description', this.formData.description);
      formData.append('amount', this.formData.amount);
      formData.append('voucher', this.formData.voucher);
      if (this.formData.imageFile) {
        formData.append('imageFile', this.formData.imageFile);
      }

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      if (this.isEdit) {
        axios.put(`/rewards/${this.formData.id}`, formData, config)
          .then(() => {
            this.fetchRewards();
            this.closeDialog();
            this.closeConfirmDialog();
          })
          .catch(error => {
            console.error("There was an error!", error);
          });
      } else {
        axios.post('/rewards/upload', formData, config)
          .then(() => {
            this.fetchRewards();
            this.closeDialog();
            this.closeConfirmDialog();
          })
          .catch(error => {
            console.error("There was an error!", error);
          });
      }
    },
    deleteReward(rewardId) {
      axios.delete(`/rewards/${rewardId}`)
        .then(() => {
          this.fetchRewards();
          this.closeConfirmDeleteDialog();
        })
        .catch(error => {
          console.error("There was an error!", error);
        });
    },
  },
};
</script>

<style scoped>
/* Add any custom styles here */
</style>

